import * as React from 'react';
import ReactPaginate from 'react-paginate';
import { getStoreLocations, getState } from '../apis/index';
import { Locations } from '../ddo/store-locators';
import { Loader } from './Loader';
import parse from 'html-react-parser';
declare const $: any;
import { Formik, FormikHelpers, FormikProps, Form, Field, FieldProps } from 'formik';

interface MyFormValues {
  city: string;
  state: string;
  pincode: string;
}
interface States {
  code: string;
  country_id: string;
  name: string;
  status: string;
  zone_id: string;
}

export interface LocationsProps {
  locations: Locations[];
  itemsPerPage: number;
  totalCount: number;
  page: number;
  noofPages: number;
  states: States[];
  city: string;
  state: string;
  pincode: string;
  loader: boolean;
}

// 'HelloProps' describes the shape of props.
// State is never set so we use the '{}' type.
export class StoreLocator extends React.Component<{}, LocationsProps> {
  public node: any;
  constructor(props: any) {
    super(props);
    this.state = {
      locations: [],
      itemsPerPage: 10,
      totalCount: 0,
      noofPages: 0,
      page: 1,
      states: [],
      city: '',
      state: '',
      pincode: '',
      loader: false
    };
  }
  componentDidMount() {
    // document.addEventListener('click', this.toggleCartView, true);
    let stateObj = {} as LocationsProps;
    this.getLocationList();
    getState((data: any) => {
      this.setState({ states: data[0]?.zone });
    });
  }
  componentWillUnmount() {
    // document.removeEventListener('click', this.toggleCartView, true);
  }
  getLocationList() {
    this.setState({ loader: true });
    let obj: any = {
      city: this.state.city,
      state: this.state.state,
      pincode: this.state.pincode,
      page: this.state.page,
      itemsPerPage: this.state.itemsPerPage
    };
    getStoreLocations(obj, (locations: { locations: Locations[]; total_items: number }) => {
      let noofPages = locations.total_items / this.state.itemsPerPage;
      // console.log(noofPages);
      // console.log(locations);
      this.setState({ totalCount: locations.total_items, noofPages: noofPages });
      if (locations.total_items) {
        this.setState({ locations: locations.locations });
      } else {
        this.setState({ locations: [] });
      }
      this.setState({ loader: false });
    });
  }
  handlePageClick = (data: { selected: number }) => {
    let selected = data.selected + 1;
    // console.log(data);
    this.setState(
      {
        page: selected
      },
      () => {
        this.getLocationList();
      }
    );
  };

  renderTableData(locations: Locations[]) {
    // console.log(locations);
    if (locations.length) {
      return locations.map((loc: Locations, i: number) => {
        return (
          <tr key={i}>
            <td>{parse(loc['Dealer Name'])}</td>
            <td>
              <a href={'tel:' + loc.Mobile}>{loc.Mobile}</a>
              <br />
              <a className='email' href={'mailto:' + loc.Email}>
                {loc.Email}
              </a>
            </td>
            <td>
              <address>
                {parse(loc.Address)} <br /> {loc.Pincode}{' '}
              </address>
              <a
                href={'https://www.google.com/maps/search/?api=1&query=' + loc.latitude + ',' + loc.longtitude}
                target='_blank'
                className='map'
              >
                <i className='fa fa-map-marker' aria-hidden='true'></i> View on map
              </a>
            </td>
            <td>
              {loc.City}
              <br />
              {loc.State}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            No sellers found
          </td>
        </tr>
      );
    }
  }
  handleReset(resetForm: any) {
    resetForm();
  }
  renderFilter(state: States[]) {
    // console.log(state);
    return (
      <div className='left'>
        <div className='cart'>
          <div className='cart-head'>Find Superfan seller</div>
          <div className='cart-body'>
            <Formik
              initialValues={{
                city: '',
                state: '',
                pincode: ''
              }}
              onSubmit={(values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
                this.setState(
                  {
                    state: values.state,
                    city: values.city,
                    pincode: values.pincode,
                    page: 1
                  },
                  () => {
                    this.getLocationList();
                  }
                );
                setSubmitting(false);
              }}
            >
              {(formProps) => {
                return (
                  <Form noValidate>
                    <div className='form-group'>
                      <Field id='city' className='form-control' name='city' placeholder='Search by City' />
                    </div>

                    {/* <span className='split'>-OR-</span> */}
                    <div className='form-group'>
                      <Field id='state' className='form-control' as='select' name='state' placeholder='Search by State'>
                        <option value={''}>Search by State</option>
                        <option value={'Outside India'}>Outside India</option>
                        {state.map((st: States) => {
                          return <option value={st.name}>{st.name}</option>;
                        })}
                      </Field>
                    </div>
                    {/* <span className='split'>-OR-</span> */}
                    <div className='form-group'>
                      <Field
                        id='pincode'
                        className='form-control'
                        name='pincode'
                        placeholder='Search by Pincode'
                        type='number'
                      />
                    </div>

                    <div className='d-flex justify-content-start align-items-center'>
                      <button className='btn btn-blue mr-10' type='submit'>
                        Submit
                      </button>
                      <a className='cursor' onClick={this.handleReset.bind(null, formProps.resetForm)}>
                        Reset
                      </a>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            {/* <input type="text" className="form-control" placeholder="Searh by City" />
            <select class="form-control">
                <option>Searh by State</option>
            </select>
            <input type="text" class="form-control" placeholder="Searh by Pincode" />
            <button class="btn btn-blue">Search</button> */}
          </div>
        </div>
        {/* <p>
          <strong>Note:</strong> The availability of your desired Superfan model cannot be guaranteed at the store.
          Please call us at <a href='tel:+91 94890 78737'>+91 94890 78737</a> before your visit.
        </p> */}
        <p>
          <strong>Note: </strong>
          The availability of your desired Superfan model cannot be guaranteed at the store. Please call us on{' '}
          <a href='tel:1800 425 78737'>1800 425 78737</a> (Toll free) or chat with us on Whatsapp <a>+91 9489078737</a>{' '}
          before your visit.
        </p>
      </div>
    );
  }
  render() {
    // console.log(this.state);

    return (
      <React.Fragment>
        {this.renderFilter(this.state.states)}
        <div className='right'>
          {this.state.loader ? (
            <Loader isShown={true} size={40} />
          ) : (
            [
              <div className='table-responsive' id='store-address'>
                <table className='table checkout'>
                  <thead>
                    <tr>
                      <th>Seller Name</th>
                      <th>Contact Details</th>
                      <th>Address</th>
                      <th>City / State</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderTableData(this.state.locations)}</tbody>
                </table>
              </div>,
              <div className='col-xs-12'>
                {this.state.totalCount > this.state.itemsPerPage ? (
                  <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.noofPages === 0 ? -1 : this.state.noofPages}
                    marginPagesDisplayed={2}
                    disabledClassName={'disabled'}
                    pageRangeDisplayed={5}
                    forcePage={this.state.page - 1}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  ></ReactPaginate>
                ) : (
                  ''
                )}
              </div>
            ]
          )}
        </div>
      </React.Fragment>
    );
  }
}
