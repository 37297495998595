import * as React from 'react';
declare var $: any;

export interface SupportProps {
  scrollPosition: number;
}

export class SupportElms extends React.Component<{}, SupportProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      scrollPosition: 0
    };
  }

  componentDidMount() {
    $(document)
      .on('scroll', () => {
        this.setState((state) => ({
          scrollPosition: $(document).scrollTop()
        }));
      })
      .scroll();
  }

  componentWillUnmount() {
    $(document).off('scroll');
  }

  scrollTop(event: any) {
    // console.log(event);
    var body = $('html, body');
    body.stop().animate({ scrollTop: 0 }, 500, 'swing');
  }
  render() {
    return (
      <React.Fragment>
        <section className='toolfree'>
          <p>
            {/* <i className="fa fa-phone "></i> */}
            <a href='https://www.superfan.in/resources/superfan-brochure-allmodels.pdf'>
              <img src='images/brochure-icon.png' alt='' />
              {/* <span className="content "> */}
              <span className='title '>Download Brochure</span>
              {/* <span className="number ">1800 425 78737</span> */}
              {/* </span> */}
            </a>
          </p>
        </section>
        <button
          id='ScrollUp'
          className={`page-scrollup btn ${this.state.scrollPosition > 300 ? '' : 'hide'}`}
          onClick={(e) => this.scrollTop(e)}
        >
          <i className='material-icons'>keyboard_arrow_up</i>
        </button>
      </React.Fragment>
    );
  }
}
