import * as React from 'react';
import { footerLinks } from '../apis/index';
declare var $: any;
import { FooterMenu, FooterChildMenu } from '../ddo/footer';

export interface FooterProps {
  scrollPosition: number;
  footerLinks: FooterMenu[];
}

export class Footer extends React.Component<{}, FooterProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      scrollPosition: 0,
      footerLinks: undefined
    };
  }

  componentDidMount() {
    let stateObj = {} as FooterProps;
    this.getFooterLinks();
  }
  getFooterLinks() {
    footerLinks((data: any) => {
      // console.log(data);
      this.setState((state) => ({
        footerLinks: data
      }));
    });
  }
  render() {
    return (
      <React.Fragment>
        <section className='footer-top'>
          <div className='container'>
            <div className='top'>
              <ul className='outer five'>
                {/* <li>
                  <div className='logo'>
                    <a href='/index.html'>
                      <img src='images/logo_white.png' alt='' />
                    </a>
                  </div>
                  <h4>
                    <a href='/superstore/products' className='btn btn-outline'>
                      Buy Online
                    </a>
                  </h4>
                </li> */}
                {this.state.footerLinks?.map((parent: FooterMenu, i: number) => {
                  return parent.isShown ? (
                    <li key={i}>
                      <h4>{parent.title}</h4>
                      <ul>
                        {parent.child.map((child: FooterChildMenu, i: number) => {
                          if (child.isShown) {
                            if (child?.type === 'logo') {
                              return (
                                <li>
                                  <div className='logo'>
                                    <a href={child?.link}>
                                      <img src={child?.imgPath} alt='' />
                                    </a>
                                  </div>
                                </li>
                              );
                            } else if (child?.type === 'button') {
                              return (
                                <li>
                                  <h4>
                                    <a href={child.link} className='btn btn-outline'>
                                      {child.title}
                                    </a>
                                  </h4>
                                </li>
                              );
                            } else {
                              return (
                                <li>
                                  <a href={child.link}>{child.title}</a>
                                </li>
                              );
                            }
                          } else {
                            return '';
                          }
                        })}
                      </ul>
                    </li>
                  ) : (
                    ''
                  );
                })}
                <li></li>
                {/* <li>
                  <h4>Products</h4>
                  <ul>
                    <li>
                      <a href='/superstore/products/s/products/listing'>Ceiling Fans</a>
                    </li>
                    <li>
                      <a href='/superstore/products/s/products/accessorylisting'>Accessories</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>About</h4>
                  <ul>
                    <li>
                      <a href='/about-company.html'>Company</a>
                    </li>
                    <li>
                      <a href='/about-superfan.html'>Superfan</a>
                    </li>
                    <li>
                      <a href='/timeline.html'>Timeline</a>
                    </li>
                    <li>
                      <a href='/media-resource.html'>Media</a>
                    </li>
                    <li>
                      <a href='/news.html'>News</a>
                    </li>
                    <li>
                      <a href='/business.html'>Superfan for Business</a>
                    </li>
                    <li>
                      <a href='/resources.html'>Resources</a>
                    </li>
                    <li>
                      <a href='/blog/'>Blog</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Contact</h4>
                  <ul>
                    <li>
                      <a href='/contact-us.html'>Get in touch</a>
                    </li>
                    <li>
                      <a href='/store-locator.html'>Store Locator</a>
                    </li>
                    <li>
                      <a href='/careers.html'>Careers</a>
                    </li>
                  </ul>
                </li>
                <li className='my-account'>
                  <h4>My Account</h4>
                  <ul>
                    <li>
                      <a href='/superstore/products/s/u/accounts'>My Account</a>
                    </li>
                    <li>
                      <a href='/superstore/products/s/u/order-list'>Order History</a>
                    </li>
                    <li>
                      <a href='/superstore/products/s/u/wishlist'>Wish List</a>
                    </li>
                    <li>
                      <a href='/superstore/products/s/u/newsletter'>Newsletter</a>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          </div>
          <div className='origin'>
            <div className='container'>
              <p>
                <strong>Country of origin:</strong> Designed and manufactured in India
              </p>
            </div>
          </div>
        </section>
        <footer>
          <div className='container'>
            <ul className='icons'>
              <li>
                <a href='https://www.facebook.com/superfanceilingfan' target='_blank'>
                  <img src='images/sm/facebook.svg' alt='Facebook' className='sm-size-footer' />
                </a>
              </li>
              <li>
                <a href='https://www.youtube.com/channel/UCYOAy2XLMnV-oas_raAkxoQ' target='_blank'>
                  <img src='images/sm/youtube.svg' alt='Youtube' className='sm-size-footer' />
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/superfan_ceiling_fan' target='_blank'>
                  <img src='images/sm/instagram.svg' alt='Instagram' className='sm-size-footer' />
                </a>
              </li>
              <li>
                <a href='https://www.linkedin.com/company/superfan-ceiling-fan' target='_blank'>
                  <img src='images/sm/linkedin.svg' alt='LinkedIn' className='sm-size-footer' />
                </a>
              </li>

              <li>
                <a href='https://twitter.com/Superfan4u' target='_blank'>
                  <img src='images/sm/twitter.svg' alt='Twitter' className='sm-size-footer' />
                </a>
              </li>
            </ul>
            <div className='pull-right'>
              <p>
                Copyright © Versa Drives Private Limited | <a href='/terms-and-conditions.html'>Terms & Conditions</a> |{' '}
                <a href='/privacy-policy.html'>Privacy Policy</a>
              </p>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
