import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Header } from './components/Header';

ReactDOM.render(<Header />, document.getElementById('Header'));

import { Footer } from './components/Footer';

ReactDOM.render(<Footer />, document.getElementById('Footer'));

import { SupportElms } from './components/SupportElms';

ReactDOM.render(<SupportElms />, document.getElementById('SupportElms'));

import { StoreLocator } from './components/StoreLocator';

if (document.getElementById('store-address')) {
  ReactDOM.render(<StoreLocator />, document.getElementById('store-address'));
}
