import axios from 'axios';
import * as qs from 'qs';

export const fetchUser = async (callback: any) => {
  const res = await axios.get('/api/current_user');
  callback(res.data);
};

export const cartList = async (callback: any) => {
  const res = await axios.get('https://www.superfan.in/superstore/superstore/index.php?route=common/cart/info');
  callback(res.data);
  // setTimeout(() => {
  //   callback({
  //     text_empty: "Your shopping cart is empty!",
  //     text_cart: "View Cart",
  //     text_checkout: "Checkout",
  //     text_recurring: "Payment Profile",
  //     text_items: "22 item(s) - Rs.82,920",
  //     text_loading: "Loading...",
  //     button_remove: "Remove",
  //     products: [
  //       {
  //         key: "YToxOntzOjEwOiJwcm9kdWN0X2lkIjtpOjg0O30=",
  //         thumb:
  //           "https://www.superfan.in/superstore/superstore/image/cache/catalog/x1_white_bug_02-47x47.jpg",
  //         name: "Super X1 DecO with decorative bug",
  //         model: "SUPERCF9008",
  //         option: [],
  //         recurring: "",
  //         quantity: 6,
  //         price: "Rs.3,820",
  //         total: "Rs.22,920",
  //         href:
  //           "https://www.superfan.in/superstore/superstore/super-x1deco-energy-efficient-ceiling-fan-buy-online",
  //         product_id: "84",
  //         stock: true,
  //         catid: "20",
  //         category: "Products",
  //       },
  //       {
  //         key:
  //           "YToyOntzOjEwOiJwcm9kdWN0X2lkIjtpOjMwO3M6Njoib3B0aW9uIjthOjE6e2k6MjQxO3M6MjoiNjUiO319",
  //         thumb:
  //           "https://www.superfan.in/newsuperstore/images/product/v1/v1_brown_01.png",
  //         name: "Ceiling Fan - Super V1",
  //         model: "SUPERCF9003",
  //         option: [{ name: "Super V1-Color ", value: "Brown", type: "image" }],
  //         recurring: "",
  //         quantity: 8,
  //         price: "Rs.3,650",
  //         total: "Rs.29,200",
  //         href:
  //           "https://www.superfan.in/superstore/superstore/super-v1-best-ceiling-fan-buy-online",
  //         product_id: "30",
  //         stock: true,
  //         catid: "20",
  //         category: "Products",
  //       },
  //       {
  //         key:
  //           "YToyOntzOjEwOiJwcm9kdWN0X2lkIjtpOjQyO3M6Njoib3B0aW9uIjthOjE6e2k6MjE3O3M6MzoiMzE1Ijt9fQ==",
  //         thumb:
  //           "https://www.superfan.in/newsuperstore/images/product/a1/a1_metallicpink_01.png",
  //         name: "Ceiling Fan - Super A1",
  //         model: "SUPERCF9001",
  //         option: [
  //           {
  //             name: "Super A1-Color ",
  //             value: "Metallic Royal Pink",
  //             type: "image",
  //           },
  //         ],
  //         recurring: "",
  //         quantity: 8,
  //         price: "Rs.3,850",
  //         total: "Rs.30,800",
  //         href:
  //           "https://www.superfan.in/superstore/superstore/super-a1-buy-ceiling-fan-online-india",
  //         product_id: "42",
  //         stock: true,
  //         catid: "20",
  //         category: "Products",
  //       },
  //     ],
  //     cancheckout: "yes",
  //     vouchers: [],
  //     totals: [
  //       { title: "Sub-Total", text: "Rs.70,271" },
  //       { title: "CGST (9%)", text: "Rs.6,324" },
  //       { title: "SGST (9%)", text: "Rs.6,324" },
  //       { title: "Total", text: "Rs.82,920" },
  //     ],
  //     cart:
  //       "https://www.superfan.in/superstore/superstore/index.php?route=checkout/cart",
  //     checkout:
  //       "https://www.superfan.in/superstore/superstore/index.php?route=checkout/checkout",
  //   });
  // }, 3000);
};

export const headerLinks = async (callback: any) => {
  const res = await axios.get('./js/header.json');
  callback(res.data);
};

export const footerLinks = async (callback: any) => {
  const res = await axios.get('./js/footer.json');
  callback(res.data);
};

export const getStoreLocations = async (data: any, callback: any) => {
  const obj: any = {
    city: 'arg1',
    state: 'arg2',
    pincode: 'arg3',
    page: 'arg4',
    itemsPerPage: 'arg5'
  };
  let param: string = '';
  Object.keys(data).forEach((key: string, index: number) => {
    if (index > 0) {
      param += '&';
    }
    param += `${obj[key]}=${data[key]}`;
  });
  const url = `https://www.superfan.in/ws/getdealerslist.php${param ? '?' + param : ''}`;
  const res = await axios.get(url);
  callback(res.data);
};

export const getState = async (callback: any) => {
  const res = await axios.get('https://www.superfan.in/newsuperstore/productsapi/getcountryzone.php?country_id=99');
  callback(res.data);
};

export const wishlistColl = async (callback: any) => {
  const res = await axios
    .get('https://www.superfan.in/superstore/superstore/index.php?route=account/wishlist')
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      if (err.response?.config?.url.endsWith('superstore/index.php?route=account/login')) {
        callback('logout');
      } else {
        throw err;
      }
    });
};

export const logout = async (callback: any) => {
  const res = await axios.get('https://www.superfan.in/superstore/superstore/index.php?route=account/logout');
  callback(res.data);
};
export const removeCart = async (data: any, callback: any) => {
  const res = await axios.post(
    'https://www.superfan.in/superstore/superstore/index.php?route=checkout/cart/remove',
    qs.stringify(data)
  );
  callback(res.data);
};
