import * as React from "react";

export interface LoaderProps {
  size?: number;
  color?: string;
  isShown: boolean;
}

export class Loader extends React.Component<LoaderProps, {}> {
  constructor(props: any) {
    super(props);
  }
  render() {
    return this.props.isShown ? (
      <div
        className="loader-ring"
        style={
          this.props.size
            ? { height: this.props.size, width: this.props.size }
            : {}
        }
      >
        <span
          style={
            this.props.color
              ? {
                  borderTopColor: this.props.color,
                  borderRightColor: "transparent",
                  borderBottomColor: this.props.color,
                  borderLeftColor: "transparent",
                }
              : {}
          }
        ></span>
      </div>
    ) : (
      ""
    );
  }
}
