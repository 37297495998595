import * as React from 'react';
import { fetchUser, cartList, wishlistColl, logout, removeCart, headerLinks } from '../apis/index';
import { Cart, Wishlist, HeaderMenus, DropdownMenus } from '../ddo/header';
import { Loader } from './Loader';
declare const $: any;

export interface HeaderProps {
  user: any;
  isLoggedIn: boolean;
  showCart: boolean;
  cartList: Cart;
  wishlistColl: Wishlist[];
  headerMenus: HeaderMenus[];
  cartCount: number;
  userName: string;
  cartIconLoader?: boolean;
}

// 'HelloProps' describes the shape of props.
// State is never set so we use the '{}' type.
export class Header extends React.Component<{}, HeaderProps> {
  public node: any;
  constructor(props: any) {
    super(props);
    this.state = {
      user: undefined,
      isLoggedIn: false,
      showCart: false,
      cartList: undefined,
      headerMenus: undefined,
      wishlistColl: [],
      cartCount: 0,
      userName: ''
    };
  }
  componentDidMount() {
    document.addEventListener('click', this.toggleCartView, true);
    let stateObj = {} as HeaderProps;
    this.getWishlistColl();
    this.getHeaderMenus();
    const isuserexist = JSON.parse(window.localStorage.getItem('currentUser'));
    if (isuserexist && isuserexist.hasOwnProperty('shipping_address')) {
      stateObj.user = isuserexist;
      stateObj.isLoggedIn = true;

      stateObj.userName = stateObj.user.shipping_address.firstname + ' ' + stateObj.user.shipping_address.lastname;

      this.getWishlistColl();
      // this.getCartList();
      // $(".isLoggedIn").show();
      // $(".isNotLoggedIn").hide();
      // $("#BottomFooter").removeClass("four").addClass("five");
      // $("#BottomFooter .my-account").show();
    } else {
      stateObj.wishlistColl = [];
      stateObj.isLoggedIn = false;
      // $(".isLoggedIn").hide();
      // $(".isNotLoggedIn").show();
      // $("#BottomFooter").removeClass("five").addClass("four");
      // $("#BottomFooter .my-account").hide();
    }
    this.setState((state) => ({ ...state, ...stateObj }));
    this.getCartList();
    // $("[data-toggle='dropdown']").dropdown();

    $(window)
      .on('resize', function () {
        if ($(window).width() > 1200) {
          $('#MobToggle, .static-nav').removeClass('active');
        }
      })
      .resize();
    $('body').on('click', '#MobToggle', function (e: any) {
      e.stopPropagation();
      if ($('.static-nav').hasClass('active') || $(this).hasClass('active')) {
        $(this).removeClass('active');
        $('.static-nav').removeClass('active');
      } else {
        $(this).addClass('active');
        $('.static-nav').addClass('active');
      }
    });
    const $menu = $('.static-nav');

    $(document).on('click', function (e: any) {
      if (
        !$menu.is(e.target) && // if the target of the click isn't the container...
        $menu.has(e.target).length === 0
      ) {
        $('#MobToggle').removeClass('active');
        $('.static-nav').removeClass('active');
      }
    });
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.toggleCartView, true);
  }
  toggleCartView = (e: any) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showCart: false
    });
  };
  getRoundOff(val: any): number {
    if (typeof val === 'string') {
      var amt = val.toLowerCase().replace('rs.', '');
      return Math.round(parseFloat(amt.replace(/[^0-9.]/g, '')));
    } else if (typeof val === 'number') {
      return Math.round(val);
    } else {
      return val;
    }
  }
  getCartCountBasedClass() {
    let classname = 'no-hover box ';
    classname += this.state.cartCount === 0 ? 'hide' : '';
    return classname;
  }
  getCartList(cartToggle?: boolean) {
    if (this.state.showCart) {
      this.setState({
        showCart: false,
        cartIconLoader: false
      });
      return;
    }
    this.setState({
      cartIconLoader: true
    });
    let stateObj = {} as HeaderProps;
    cartList((data: Cart) => {
      stateObj.cartList = data;
      let count = stateObj.cartList.products.map((v: any) => v.quantity);
      stateObj.cartCount = count.reduce((a, b) => a + b, 0);
      stateObj.cartIconLoader = false;
      if (cartToggle) {
        stateObj.showCart = true;
      }
      this.setState(
        (state) => ({ ...state, ...stateObj }),
        () => {
          if (cartToggle) {
            // $(".cart.dropdown").dropdown("toggle");
          }
        }
      );
    });
  }
  renderCartHtml(cart: Cart) {
    // console.log(cart);
    return (
      <React.Fragment>
        <ul className='top cursor-default'>
          {cart.products.map((product: any, i: number) => {
            return (
              <li key={i}>
                <div className='media'>
                  <div className='media-left'>
                    <img src={product.thumb} alt='' />
                  </div>
                  <div className='media-body'>
                    <span className='icon' onClick={() => this.removeFromCart(product.key)}>
                      <i className='material-icons'>close</i>
                    </span>
                    <h5 className='media-heading'>{product.name}</h5>
                    {product.option && product.option.length > 0 ? (
                      <p>
                        Color: <strong>{product.option[0].value}</strong>
                      </p>
                    ) : (
                      ''
                    )}
                    <p>{product.quantity} No.s</p>
                    <p className='price text-right'>
                      <i className='fa fa-inr' aria-hidden='true'></i>
                      {this.getRoundOff(product.price)}
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <ul className='bottom'>
          {cart.totals.map((total: any, i: number) => {
            if (i == 0) {
              return (
                <li className='sub' key={`cost_${i}`}>
                  <span className='cart-label bold'>{total.title}</span>
                  <span className='cart-value'>
                    <i className='fa fa-inr' aria-hidden='true'></i>
                    {this.getRoundOff(total.text.split('Rs.')[1])}
                  </span>
                </li>
              );
            } else if (i == cart.totals.length - 1) {
              return (
                <li className='total' key={`cost_${i}`}>
                  <span className='cart-label bold'>Pay: </span>
                  <span className='cart-value'>
                    <i className='fa fa-inr' aria-hidden='true'></i>
                    {this.getRoundOff(total.text.split('Rs.')[1])}
                  </span>
                </li>
              );
            } else {
              return (
                <li key={`cost_${i}`}>
                  <span className='cart-label'>{total.title}</span>
                  <span className='cart-value'>
                    <i className='fa fa-inr' aria-hidden='true'></i>
                    {this.getRoundOff(total.text.split('Rs.')[1])}
                  </span>
                </li>
              );
            }
          })}
        </ul>
      </React.Fragment>
    );
  }
  getWishlistColl() {
    wishlistColl((data: any) => {
      if (typeof data === 'string' && (data === 'logout' || data.includes('<!DOCTYPE html>'))) {
        // console.log(data);
        this.doLogout(undefined);
      } else if (data && data?.wishlist) {
        // console.log(data);
        this.setState((state) => ({
          wishlistColl: data.wishlist.map((v: any) => parseInt(v.product_id))
        }));
      }
    });
  }
  getHeaderMenus() {
    headerLinks((data: any) => {
      // console.log(data);
      this.setState((state) => ({
        headerMenus: data
      }));
    });
  }
  doLogout(event: any) {
    let stateObj = {} as HeaderProps;
    logout((data: any) => {
      // console.log(data);
      if (data) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        stateObj.user = undefined;
        stateObj.isLoggedIn = false;
        stateObj.wishlistColl = [];
        stateObj.cartCount = 0;
        stateObj.cartList = undefined;
        stateObj.userName = '';
        // location.reload();
        this.setState((state) => ({ ...stateObj }));
      } else {
        console.error('Logout failed.');
      }
    });
  }

  removeFromCart(key: string) {
    removeCart({ key: key }, (data: any) => {
      // console.log(data);
      if (data.total) {
        // console.log('Item removed successfully');
        this.setState(
          {
            showCart: false
          },
          () => {
            this.getCartList();
          }
        );
      } else {
        console.error('Error occured while removing item please try again.');
      }
    });
  }
  toggleStaticNav(event: any): void {
    $('#MobToggle, .static-nav').removeClass('active');
  }

  render() {
    // console.log(this.state);
    return (
      <React.Fragment>
        <div className='container-fluid'>
          <div className='logo'>
            <a href='/index.html'>
              <span className='hidden-xs'>
                <img className='icon' src='images/logo_blue.png' alt='Superfan Logo' />
              </span>
              <span className='hidden-xs'>
                <img className='icon-1' src='images/logo_white.png' alt='Superfan Logo' />
              </span>
              <span className='visible-xs'>
                <img className='icon' src='images/logo_blue_icon.png' alt='Superfan Logo' />
              </span>
              <span className='visible-xs'>
                <img className='icon-1' src='images/logo_white_icon.png' alt='Superfan Logo' />
              </span>
            </a>
          </div>
          <div
            className='collapse navbar-collapse hidden-xs hidden-sm hidden-md pull-left'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav'>
              {this.state.headerMenus?.map((header: HeaderMenus, i: number) => {
                if (header.isShown) {
                  if (header?.type !== 'dropdown') {
                    return (
                      <li key={i}>
                        <a href={header.link}>{header.title}</a>
                      </li>
                    );
                  } else {
                    return (
                      <li className='dropdown' key={i}>
                        <a className='dropdown-toggle' data-toggle='dropdown' href='javascript:void(0);'>
                          {header.title}
                          <i className='fa fa-angle-down' aria-hidden='true'></i>
                        </a>
                        <ul className='dropdown-menu animate fiu'>
                          {header.child.map((child: DropdownMenus, j: number) => {
                            if (child.isShown) {
                              return (
                                <li key={j}>
                                  <a href={child.link}>{child.title}</a>
                                </li>
                              );
                            } else {
                              return '';
                            }
                          })}
                        </ul>
                      </li>
                    );
                  }
                } else {
                  return '';
                }
              })}
              {/* <li>
                <a href='/index.html'>Home</a>
              </li>
              <li className='dropdown'>
                <a className='dropdown-toggle' data-toggle='dropdown' href='javascript:void(0);'>
                  Products
                  <i className='fa fa-angle-down' aria-hidden='true'></i>
                </a>
                <ul className='dropdown-menu animate fiu'>
                  <li>
                    <a href='/superstore/products/s/products/listing'>Ceiling Fans</a>
                  </li>
                  <li>
                    <a href='/superstore/products/s/products/accessorylisting'>Accessories</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href='/innovation.html'>Innovation</a>
              </li>
              <li>
                <a href='/superfan-story.html'>About</a>
              </li>
              <li>
                <a href='/recognitions.html'>Recognitions</a>
              </li>
              <li>
                <a href='/careers.html'>Careers</a>
              </li>
              <li>
                <a href='/superfan-support.html'>Support</a>
              </li>
              <li>
                <a href='/blog/'>Blog</a>
              </li> */}
              {/* <li className='dropdown'>
                <a className='dropdown-toggle' data-toggle='dropdown' href='#'>
                  About
                  <i className='fa fa-angle-down' aria-hidden='true'></i>
                </a>
                <ul className='dropdown-menu animate fiu'>
                  <li>
                    <a href='/about-company.html'>Story of Superfan</a>
                  </li>
                  <li>
                    <a href='/about-superfan.html'>About Superfan</a>
                  </li>
                  <li>
                    <a href='/timeline.html'>Timeline</a>
                  </li>
                  <li>
                    <a href='/media-resource.html'>Media</a>
                  </li>
                  <li>
                    <a href='/news.html'>News</a>
                  </li>
                  <li>
                    <a href='/business.html'>Superfan for Business</a>
                  </li>
                  <li>
                    <a href='/careers.html'>Careers</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href='/contact-us.html'>Contact</a>
              </li>
              <li className='dropdown'>
                <a className='dropdown-toggle' data-toggle='dropdown' href='#'>
                  Customer Care
                  <i className='fa fa-angle-down' aria-hidden='true'></i>
                </a>
                <ul className='dropdown-menu animate fiu'>
                  <li>
                    <a href='/onlineenquiry.php'>Online Enquiry</a>
                  </li>
                  <li>
                    <a href='/customercare.php'>Register for Warranty</a>
                  </li>
                  <li>
                    <a href='/customercalls.php'>Register a Complaint</a>
                  </li>
                  <li>
                    <a href='/fan-of-superfan.html'>
                      Recommend Superfan & <br />
                      Earn Rewards
                    </a>
                  </li>
                  <li>
                    <a href='/manufacturers-warranty.html'>Manufacturers Warranty</a>
                  </li>
                  <li>
                    <a href='/faq.html'>FAQs</a>
                  </li>
                  <li>
                    <a href='/cancellation-and-returns.html'>Cancellation & Returns</a>
                  </li>
                  <li>
                    <a href='/shipping-policy.html'>Shipping Policy</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href='/resources.html'>Resources</a>
              </li>
              <li>
                <a href='/blog/'>Blog</a>
              </li> */}
            </ul>
            {/* <div className='pull-left mt-10'>
              <a className='btn btn-mat-blue outline' href='/store-locator.html'>
                Store Locator
              </a>
            </div> */}
            <div className='pull-left'>
              <p className='toll'>
                {/* <span className='name'>Whatsapp Chat</span> 
                <span className='number'>+91 9489078737</span> */}

                <span className='number'>
                  <i className='fa fa-phone' aria-hidden='true'></i>&nbsp; 1800 425 78737&nbsp;
                  {/* <span className='name'>(Toll Free)</span> */}
                </span>
                <span className='number'>
                  <i className='fa fa-whatsapp' aria-hidden='true'></i>&nbsp; +91 9489078737&nbsp;
                  {/* <span className='name'>(Chat)</span> */}
                </span>
              </p>
            </div>
          </div>
          <div className='pull-right user-details'>
            <ul className='nav'>
              <li className='visible-xs visible-sm visible-md'>
                <div className='pull-left'>
                  <p className='toll'>
                    {/* <span className='name'>Whatsapp Chat</span> <span className='number'>+91 9489078737</span> */}
                    <span className='number'>
                      <i className='fa fa-phone' aria-hidden='true'></i>&nbsp; 1800 425 78737&nbsp;
                      {/* <span className='name'>(Toll Free)</span> */}
                    </span>
                    <span className='number'>
                      <i className='fa fa-whatsapp' aria-hidden='true'></i>&nbsp; +91 9489078737&nbsp;
                      {/* <span className='name'>(Chat)</span> */}
                    </span>
                  </p>
                </div>
              </li>

              {this.state.isLoggedIn ? (
                <li className='isLoggedIn wishlist cursor'>
                  <a href='/superstore/products/s/c/wishlist' className='box no-hover'>
                    <i className='material-icons'>favorite_border</i>
                    <span className='count wishlist-count'>{this.state.wishlistColl.length}</span>
                  </a>
                </li>
              ) : (
                ''
              )}
              <li className={`cart cursor visible-xs ${this.state.cartCount === 0 ? 'hide' : ''}`}>
                <a href='/superstore/products/s/c/cart' className='no-hover box'>
                  <i className='material-icons'>shopping_cart</i>
                  <span id='cartCount' className='count wishlist-count'>
                    {this.state.cartCount}
                  </span>
                </a>
              </li>
              <li
                className={`cart cursor dropdown hidden-xs ${this.state.cartCount === 0 ? 'hide' : ''} ${
                  this.state.showCart ? 'open' : ''
                }`}
                ref={(node) => (this.node = node)}
              >
                <a href='javascript:void(0);' className='no-hover box' onClick={(e) => this.getCartList(true)}>
                  <i className='material-icons'>shopping_cart</i>
                  <span id='cartCount1' className='count'>
                    {this.state.cartCount}
                  </span>
                  <span className='caret-arr fiu'></span>
                  <Loader isShown={this.state.cartIconLoader} />
                </a>
                <div className='dropdown-menu cart-menu fiu pull-right'>
                  <div className='dropdown-wrapper'>
                    {this.state.cartCount ? (
                      <div id='CartItems'>
                        <div id='CartItemsList'>{this.renderCartHtml(this.state.cartList)}</div>
                        <div className='text-center mt-10 buttons'>
                          <a href='/superstore/products/s/c/cart' className='btn btn-outline one' type='button'>
                            View Cart
                          </a>
                          <a href='/superstore/products/s/c/place-order' className='btn btn-fill one' type='button'>
                            Checkout
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div id='noCartItems'>
                        <p className='text-center no-mar'>No items in cart</p>
                      </div>
                    )}
                  </div>
                </div>
              </li>
              {!this.state.isLoggedIn
                ? [
                    <li className='isNotLoggedIn hidden-xs hidden-sm hidden-md'>
                      <a href='/superstore/products/s/u/register'>Register</a>
                    </li>,
                    <li className='isNotLoggedIn hidden-xs hidden-sm hidden-md'>
                      <a href='/superstore/products/s/u/login'>Login</a>
                    </li>
                  ]
                : ''}
              {this.state.isLoggedIn ? (
                <li className='isLoggedIn dropdown mobile'>
                  <a className='dropdown-toggle userprofile' data-toggle='dropdown' href='#'>
                    <img className='user-icon one' src='images/store/user.png' alt='' />
                    <img className='user-icon two' src='images/store/user_white.png' alt='' />
                  </a>
                  <ul className='dropdown-menu animate fiu pull-right'>
                    <li>
                      <a className='user_name'>{this.state.userName}</a>
                    </li>
                    <li>
                      <a href='/superstore/products/s/u/'>Account / Profile</a>
                    </li>
                    <li className='cursor'>
                      <a onClick={(e) => this.doLogout(e)}>Logout</a>
                    </li>
                  </ul>
                </li>
              ) : (
                ''
              )}
              <li className='mob-toggle visible-xs visible-sm visible-md' id='MobToggle'>
                <span></span>
                <span></span>
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div className='static-nav visible-xs visible-sm visible-md'>
          <ul>
            {this.state.headerMenus?.map((header: HeaderMenus, i: number) => {
              if (header.isShown) {
                if (header?.type !== 'dropdown') {
                  return (
                    <li key={i}>
                      <a href={header.link}>{header.title}</a>
                    </li>
                  );
                } else {
                  return (
                    <li className='dropdown' key={i}>
                      <a className='dropdown-toggle' data-toggle='dropdown' href='javascript:void(0);'>
                        {header.title}
                        <i className='fa fa-angle-down' aria-hidden='true'></i>
                      </a>
                      <ul className='dropdown-menu'>
                        {header.child.map((child: DropdownMenus, j: number) => {
                          if (child.isShown) {
                            return (
                              <li key={j}>
                                <a onClick={(e) => this.toggleStaticNav(e)} href={child.link}>
                                  {child.title}
                                </a>
                              </li>
                            );
                          } else {
                            return '';
                          }
                        })}
                      </ul>
                    </li>
                  );
                }
              } else {
                return '';
              }
            })}
            {/* <li className='active'>
              <a href='/index.html'>Home</a>
            </li>
            <li className='dropdown'>
              <a className='dropdown-toggle' data-toggle='dropdown' href='javascript:void(0);'>
                Products
                <i className='fa fa-angle-down' aria-hidden='true'></i>
              </a>
              <ul className='dropdown-menu'>
                <li>
                  <a onClick={(e) => this.toggleStaticNav(e)} href='/superstore/products/s/products/listing'>
                    Ceiling Fans
                  </a>
                </li>
                <li>
                  <a onClick={(e) => this.toggleStaticNav(e)} href='/superstore/products/s/products/accessorylisting'>
                    Accessories
                  </a>
                </li>
              </ul>
            </li>
            <li className='dropdown'>
              <a className='dropdown-toggle' data-toggle='dropdown' href='#'>
                About
                <i className='fa fa-angle-down' aria-hidden='true'></i>
              </a>
              <ul className='dropdown-menu'>
                <li>
                  <a href='/about-company.html'>About Company</a>
                </li>
                <li>
                  <a href='/about-superfan.html'>About Superfan</a>
                </li>
                <li>
                  <a href='/timeline.html'>Timeline</a>
                </li>
                <li>
                  <a href='/media-resource.html'>Media</a>
                </li>
                <li>
                  <a href='/news.html'>News</a>
                </li>
                <li>
                  <a href='/business.html'>Superfan for Business</a>
                </li>
                <li>
                  <a href='/careers.html'>Careers</a>
                </li>
              </ul>
            </li>
            <li>
              <a href='/contact-us.html'>Contact</a>
            </li>
            <li className='dropdown'>
              <a className='dropdown-toggle' data-toggle='dropdown' href='#'>
                Customer Care
                <i className='fa fa-angle-down' aria-hidden='true'></i>
              </a>
              <ul className='dropdown-menu'>
                <li>
                  <a href='/onlineenquiry.php'>Online Enquiry</a>
                </li>
                <li>
                  <a href='/customercare.php'>Register for Warranty</a>
                </li>
                <li>
                  <a href='/customercalls.php'>Register a Complaint</a>
                </li>
                <li>
                  <a href='/fan-of-superfan.html'>
                    Recommend Superfan & <br />
                    Earn Rewards
                  </a>
                </li>
                <li>
                  <a href='/manufacturers-warranty.html'>Manufacturers Warranty</a>
                </li>
                <li>
                  <a href='/faq.html'>FAQs</a>
                </li>
                <li>
                  <a href='/cancellation-and-returns.html'>Cancellation & Returns</a>
                </li>
                <li>
                  <a href='/shipping-policy.html'>Shipping Policy</a>
                </li>
              </ul>
            </li>
            <li>
              <a href='/resources.html'>Resources</a>
            </li>
            <li>
              <a href='/blog/'>Blog</a>
            </li>

            <li>
              <a href='/store-locator.html'>Store Locator</a>
            </li> */}
            {!this.state.isLoggedIn
              ? [
                  <li className='isNotLoggedIn visible-xs visible-sm'>
                    <a href='/superstore/products/s/u/login'>Login</a>
                  </li>,
                  <li className='isNotLoggedIn visible-xs visible-sm'>
                    <a href='/superstore/products/s/u/register'>Register</a>
                  </li>
                ]
              : ''}
          </ul>
          <div className='social'>
            <a href='https://www.facebook.com/superfanceilingfan' target='_blank'>
              <img src='images/sm/facebook-dark.svg' alt='Facebook' />
            </a>
            <a href='https://www.youtube.com/channel/UCYOAy2XLMnV-oas_raAkxoQ' target='_blank'>
              <img src='images/sm/youtube-dark.svg' alt='Youtube' />
            </a>
            <a href='https://www.instagram.com/superfan_ceiling_fan' target='_blank'>
              <img src='images/sm/instagram-dark.svg' alt='Instagram' />
            </a>
            <a href='https://www.linkedin.com/company/superfan-ceiling-fan' target='_blank'>
              <img src='images/sm/linkedin-dark.svg' alt='LinkedIn' />
            </a>
            <a href='https://twitter.com/Superfan4u' target='_blank'>
              <img src='images/sm/twitter-dark.svg' alt='Twitter' />
            </a>
          </div>
          <div className='contact-cont mt-20 pull-left pl-20'>
            <div className='info-left'>
              <img src='images/fb-call.png' />
            </div>
            <div className='info-right text-left'>
              <p>Customer support </p>
              <h4>1800 425 78737</h4>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
